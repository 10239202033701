import { merge } from 'lodash';
import { create, StoreApi, UseBoundStore } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { AuthSlice, TAuthSlice, initialState as authInitialState } from './slices/auth';
import { THeaderSlice, HeaderSlice, initialState as headerInitialState } from './slices/header';
import { StationSlice, TStationSlice, initialState as stationInitialState } from './slices/station';
import { TUserSlice, UserSlice, initialState as userInitialState } from './slices/user';

type Slices = TAuthSlice & TUserSlice & THeaderSlice & TStationSlice & TCommonActions;

type WithSelectors<S> = S extends { getState: () => infer T }
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never;

const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(_store: S) => {
  const store = _store as WithSelectors<typeof _store>;
  store.use = {};
  for (const k of Object.keys(store.getState())) {
    (store.use as any)[k] = () => store((s) => s[k as keyof typeof s]);
  }

  return store;
};

export interface TCommonActions {
  logout: () => void;
}

const useBoundStoreBase = create<Slices>()(
  persist(
    devtools((...a) => ({
      ...AuthSlice(...a),
      ...UserSlice(...a),
      ...HeaderSlice(...a),
      ...StationSlice(...a),
      logout: () => {
        localStorage.clear();
        a[0]({
          ...authInitialState,
          ...userInitialState,
          ...stationInitialState,
          ...headerInitialState,
        });
      },
    })),
    {
      name: 'store',
      storage: createJSONStorage(() => localStorage),
      merge: (persistedState, currentState) => merge(currentState, persistedState),
      partialize: (state) =>
        Object.fromEntries(
          (Object.keys(state) as (keyof typeof state)[])
            .filter((key) => !['user'].includes(key))
            .map((key) => [key, state[key]])
        ) as unknown as Partial<Slices>,
    }
  )
);

const useBoundStore = createSelectors(useBoundStoreBase);

export default useBoundStore;
