import { Suspense, lazy, useEffect } from 'react';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';

import Loading from '../../pages/Loading';

const InvalidEmail = lazy(() => import('../../pages/Error/InvalidEmail'));
const Login = lazy(() => import('../../pages/Login'));

const LoginOutlet = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, [navigate]);

  return <Outlet />;
};

const LoginRoutes = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <Suspense fallback={<Loading />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        path='/error/bad-email'
        element={
          <Suspense fallback={<Loading />}>
            <InvalidEmail />
          </Suspense>
        }
      />
      <Route path='*' element={<LoginOutlet />} />
    </Routes>
  );
};

export default LoginRoutes;
