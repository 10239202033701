// import { Card } from './game';

import { CompletedTask } from '../services/task.service';

import { Item } from './item';
import { ItemSets } from './set';

export enum UserRole {
  STUDENT = 'STUDENT',
  ADMIN = 'SYSTEM_ADMIN',
  SINGLE_STATION = 'SINGLE_STATION',
  DUO_STATION = 'DUO_STATION',
  BATTLE_STATION = 'BATTLE_STATION',
}

export interface User {
  _id: string;
  skName: string;
  name: string;
  picture: string;
  roles: UserRole[];
  gold: number;
  score: number;
  goldFromStations: number;
  stationsPlayed: number;
  stationPassword: string;
  items: Array<Item>;
  itemSets: Array<ItemSets>;
  completedTasks: Array<CompletedTask>;
}
