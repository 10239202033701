import { useNavigate } from 'react-router-dom';

import Icon from '../Icon';

const BackHomeButton = () => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate('/')}
      className='flex flex-row items-center gap-x-2 rounded-full bg-[#4285F4] px-3 py-2'
    >
      <Icon.Home className='h-4 w-4 fill-white' />
      <p className='text-sm text-white'>Go Home</p>
    </button>
  );
};

export default BackHomeButton;
