import { StateCreator } from 'zustand';

import { API_URL } from '../configs';
import { axios } from '../utils/custom_axios';

import { Response, Station } from '../types';

export interface TStationState {
  stations: Station[];
}

export interface TStationAction {
  getAllStations: () => void;
}

export interface TStationSlice extends TStationState, TStationAction {}

export const initialState: TStationState = {
  stations: [],
};

export const StationSlice: StateCreator<
  TStationSlice,
  [],
  [['zustand/persist', unknown], ['zustand/devtools', never]],
  TStationSlice
> = (set) => ({
  ...initialState,
  getAllStations: async () => {
    try {
      const { data } = await axios.get<Response<Station[]>>(`${API_URL}station`);
      set({ stations: data.payload });
    } catch (err) {
      console.error(err);
    }
  },
});
