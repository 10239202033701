import { FC, useMemo } from 'react';

import { ReactComponent as Banner } from '../../assets/svgs/Banner.svg';
import { ReactComponent as Golds } from '../../assets/svgs/PileOfGolds.svg';
import { ReactComponent as Star } from '../../assets/svgs/Star.svg';
import useBoundStore from '../../store';

import { UserRole } from '../../types';

const Header: FC = () => {
  const user = useBoundStore.use.user();

  const isPlayer = useMemo(() => user.roles[0] === UserRole.STUDENT, [user.roles]);

  return (
    <header className='fixed left-0 top-0 z-[100] flex w-screen flex-col items-center bg-transparent px-5 py-3'>
      <Banner className='relative h-auto w-full max-w-[330px]' />
      <h1
        className='absolute top-[calc(20px+330px*89/223*0.324-1.25/2*(20px+14px))] z-10 text-xl/tight 
          font-semibold uppercase text-[rgb(0,10,20)] shadow-[rgb(0,10,20)]/70 text-shadow'
      >
        {user.name}
        <p className='text-center text-sm/tight font-normal shadow-[#252641] text-shadow'>
          {' '}
          - {user.skName} -
        </p>
      </h1>
      {isPlayer ? (
        <div
          className='absolute bottom-[calc(330px*31/223-16px)] -my-5 flex w-[calc(330px*132.38/222.55-8px)] flex-row items-center 
            justify-between gap-x-2 rounded-full bg-transparent py-2'
        >
          <div className='flex flex-row items-center gap-x-2'>
            <Golds className='h-8 w-auto' />
            <p id='user gold' className='pt-1 text-base font-bold'>
              {user.gold}
            </p>
          </div>
          <div className='flex flex-row items-center gap-x-2'>
            <Star className='h-8 w-auto' />
            <p className='pt-1 text-base font-bold'>{user.score}</p>
          </div>
        </div>
      ) : null}
    </header>
  );
};

export default Header;
