import Lottie from 'lottie-react';

const Loading = () => {
  return (
    <div className='flex h-full bg-[rgba(0,0,0,0)]'>
      <div className='bg-[rgba(0,0,0,0) flex h-auto w-full items-center justify-center'>
        <Lottie animationData={require('../../assets/animations/loading_animation.json')} />
      </div>
    </div>
  );
};

export default Loading;
