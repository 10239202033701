import { DetailedHTMLProps, HTMLAttributes, ReactNode, forwardRef, useEffect } from 'react';

import { DEFAULT_DESCRIPTION, DEFAULT_TITLE, TITLE } from '../configs';

type PageProps = {
  children: ReactNode;
  title?: string;
  description?: string;
  style?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>['style'];
};

const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title, description, style, ...props }, _ref) => {
    useEffect(() => {
      document.title = `${title ? `${title} | ${TITLE}` : DEFAULT_TITLE}`;
      document
        ?.querySelector('meta[name="description"]')
        ?.setAttribute('content', description || DEFAULT_DESCRIPTION || '');
    }, [title, description]);

    return (
      <div
        className='h-full w-screen'
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        style={{ ...style, position: 'relative' }}
      >
        {children}
      </div>
    );
  }
);
Page.displayName = 'Page';

export default Page;
