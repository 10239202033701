export enum TaskDifficulty {
  EASY = 'EASY',
  HARD = 'HARD',
}

export enum TaskName {
  QUESTION_1_EASY = 'QUESTION_1_EASY',
  QUESTION_2_EASY = 'QUESTION_2_EASY',
  QUESTION_3_EASY = 'QUESTION_3_EASY',
  QUESTION_4_EASY = 'QUESTION_4_EASY',
  QUESTION_5_EASY = 'QUESTION_5_EASY',

  QUESTION_1_HARD = 'QUESTION_1_HARD',
  QUESTION_2_HARD = 'QUESTION_2_HARD',
  QUESTION_3_HARD = 'QUESTION_3_HARD',
}

type TaskInfo = {
  [name in TaskName]: {
    difficulty: TaskDifficulty;
    title: string;
    description: string;
    image: string;
  };
};

export const TASK_LIST: TaskInfo = {
  [TaskName.QUESTION_1_EASY]: {
    title: 'Question 1 Easy',
    difficulty: TaskDifficulty.EASY,
    description: "Easy: Answer 'A' to this question",
    image: 'easy/1.png',
  },
  [TaskName.QUESTION_2_EASY]: {
    title: 'Question 2 Easy',
    difficulty: TaskDifficulty.EASY,
    description: "Easy: Answer 'B' to this question",
    image: 'easy/2.png',
  },
  [TaskName.QUESTION_3_EASY]: {
    title: 'Question 3 Easy',
    difficulty: TaskDifficulty.EASY,
    description: "Easy: Answer 'C' to this question",
    image: 'easy/3.png',
  },
  [TaskName.QUESTION_4_EASY]: {
    title: 'Question 4 Easy',
    difficulty: TaskDifficulty.EASY,
    description: "Easy: Answer 'D' to this question",
    image: 'easy/4.png',
  },
  [TaskName.QUESTION_5_EASY]: {
    title: 'Question 5 Easy',
    difficulty: TaskDifficulty.EASY,
    description: "Easy: Answer 'E' to this question",
    image: 'easy/5.png',
  },
  [TaskName.QUESTION_1_HARD]: {
    title: 'Question 1 Hard',
    difficulty: TaskDifficulty.HARD,
    description: "Hard: Answer 'A' to this question",
    image: 'hard/1.png',
  },
  [TaskName.QUESTION_2_HARD]: {
    title: 'Question 2 Hard',
    difficulty: TaskDifficulty.HARD,
    description: "Hard: Answer 'B' to this question",
    image: 'hard/2.png',
  },
  [TaskName.QUESTION_3_HARD]: {
    title: 'Question 3 Hard',
    difficulty: TaskDifficulty.HARD,
    description: "Hard: Answer 'C' to this question",
    image: 'hard/3.png',
  },
};
