export enum Status {
  PREPARATION = 'PREPARATION',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export type Station = {
  _id: string;
  name: string;
  type: string;
};
