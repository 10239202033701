export enum NotificationType {
  COMPLETED_SINGLE_STATION = 'COMPLETED_SINGLE_STATION',
  COMPLETED_DUO_STATION = 'COMPLETED_DUO_STATION',
  COMPLETED_BATTLE_STATION = 'COMPLETED_BATTLE_STATION',
  BUY_ITEM = 'BUY_ITEM',
  FORGE_ITEM_SET = 'FORGE_ITEM_SET',
  SHOP_RENEW = 'SHOP_RENEW',
  GACHA = 'GACHA',
  TASK = 'TASK',
  SYSTEM_ADMIN = 'SYSTEM_ADMIN',
}

export type Notification = {
  _id: string;
  userId: string;
  type: string;
  description: string;
  createdAt: number;
};
