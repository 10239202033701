import { StateCreator } from 'zustand';

export interface THeaderState {
  isAsideOpen: boolean;
  hasNewNotifications: boolean;
}

export interface THeaderAction {
  toggleAside: () => void;
  readNotification: () => void;
  newNotification: () => void;
}

export interface THeaderSlice extends THeaderState, THeaderAction {}

export const initialState: THeaderState = {
  isAsideOpen: false,
  hasNewNotifications: false,
};

export const HeaderSlice: StateCreator<
  THeaderSlice,
  [],
  [['zustand/persist', unknown], ['zustand/devtools', never]],
  THeaderSlice
> = (set) => ({
  isAsideOpen: false,
  hasNewNotifications: false,
  toggleAside: () => set((state) => ({ isAsideOpen: !state.isAsideOpen })),
  readNotification: () =>
    set(() => {
      return { hasNewNotifications: false };
    }),
  newNotification: () =>
    set(() => {
      return { hasNewNotifications: true };
    }),
});
