import { StateCreator } from 'zustand';

import UserService from '../services/user.service';

import { TAuthSlice } from './auth';

import { User } from '../types';

export interface TUserSlice {
  user: User;
  getUserProfile: () => Promise<void>;
}

export const initialState = {
  _id: '',
  skName: '',
  name: '',
  picture: '',
  roles: [],
  gold: 0,
  score: 0,
  goldFromStations: 0,
  stationsPlayed: 0,
  stationPassword: '',
  items: [],
  itemSets: [],
  completedTasks: [],
};

export const UserSlice: StateCreator<
  TUserSlice & TAuthSlice,
  [],
  [['zustand/persist', unknown], ['zustand/devtools', never]],
  TUserSlice
> = (set) => ({
  user: initialState,
  getUserProfile: async () => {
    try {
      const { data } = await UserService.getUserProfile();
      const { payload } = data;

      // if (
      //   (payload && payload.roles.includes(UserRole.SINGLE_STATION)) ||
      //   payload.roles.includes(UserRole.DUO_STATION) ||
      //   payload.roles.includes(UserRole.BATTLE_STATION)
      // ) {
      //   if (!window.location.href.includes('/admin/station')) {
      //     window.location.replace(window.location.href + 'admin/station');
      //   }
      // }

      set((state) => ({
        user: {
          ...state.user,
          _id: payload?._id,
          skName: payload?.skName,
          name: payload?.name,
          picture: payload?.picture,
          roles: payload?.roles,
          gold: payload?.gold,
          score: payload?.score,
          goldFromStations: payload?.goldFromStations,
          stationsPlayed: payload?.stationsPlayed,
          stationPassword: payload?.stationPassword,
          items: payload?.items,
          itemSets: payload?.itemSets,
        },
        isAuthenticated: true,
      }));
    } catch (error: any) {
      set({ user: { ...initialState }, isAuthenticated: false });
    }
  },
});
