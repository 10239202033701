export enum SocketEvent {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',

  NOTIFICATION = 'NOTIFICATION',
  LEADERBOARD = 'LEADERBOARD',
  ADMIN_STATION_SESSION_CHANGE = 'ADMIN_STATION_SESSION_CHANGE',

  STATION_LIST_CHANGE = 'STATION_LIST_CHANGE',

  MY_STATION_SESSION_CHANGE = 'MY_STATION_SESSION_CHANGE',
  SHOP_RENEW = 'SHOP_RENEW',
}
