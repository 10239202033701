import { API_URL } from '../configs';
import { axios } from '../utils/custom_axios';

import { Response, Notification, User } from '../types';

export enum UserRole {
  STUDENT = 'STUDENT',
  ADMIN = 'ADMIN',
}

const getUserProfile = () => axios.get<Response<User>>(`${API_URL}me`);

type GetAllNotificationsReturnType = {
  total: number;
  pageCount: number;
  pageSize: number;
  result: Notification[];
};

const getAllNotifications = (pageNumber: number) =>
  axios.get<Response<GetAllNotificationsReturnType>>(
    `${API_URL}me/notifications?pageNumber=${pageNumber}`
  );

const UserService = {
  getUserProfile,
  getAllNotifications,
};

export default UserService;
