import { ReactComponent as ArrowLeftBackIcon } from '../../assets/svgs/ArrowLeftBackIcon.svg';
import { ReactComponent as CardIcon } from '../../assets/svgs/CardIcon.svg';
import { ReactComponent as CheckinCode } from '../../assets/svgs/CheckinCode.svg';
import { ReactComponent as Chevron } from '../../assets/svgs/ChevronIcon.svg';
import { ReactComponent as CodeIcon } from '../../assets/svgs/CodeIcon.svg';
import { ReactComponent as CrownPiecesIcon } from '../../assets/svgs/CrownPiecesIcon.svg';
import { ReactComponent as GDSCIcon } from '../../assets/svgs/GdscIcon.svg';
import { ReactComponent as Home } from '../../assets/svgs/HomeIcon.svg';
import { ReactComponent as InventoryIcon } from '../../assets/svgs/InventoryIcon.svg';
import { ReactComponent as ShopClose } from '../../assets/svgs/ShopClose.svg';
import { ReactComponent as ShoppingIcon } from '../../assets/svgs/ShoppingIcon.svg';
import { ReactComponent as ViewHeaderline } from '../../assets/svgs/VIewHeadlineIcon.svg';

export default {
    ArrowLeftBackIcon,
    CardIcon,
    CheckinCode,
    Chevron,
    CodeIcon,
    CrownPiecesIcon,
    GDSCIcon,
    Home,
    InventoryIcon,
    ShopClose,
    ShoppingIcon,
    ViewHeaderline,
}