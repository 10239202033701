import { FC } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

import { API_URL } from '../../configs';
import useBoundStore from '../../store';
import { axios } from '../../utils/custom_axios';

const LoginButton: FC = () => {
  const setToken = useBoundStore.use.setToken();
  const getUserProfile = useBoundStore.use.getUserProfile();
  const navigate = useNavigate();

  return (
    <GoogleLogin
      onSuccess={async (credentialResponse) => {
        try {
          const response = await axios.post(`${API_URL}auth/login`, {
            idToken: credentialResponse.credential,
          });
          setToken(response.data.payload.token);
          await getUserProfile();
        } catch (error) {
          navigate('/error/bad-email');
        }
      }}
      onError={() => {
        console.log('Login Failed');
      }}
      useOneTap
    />
  );
};

export default LoginButton;
